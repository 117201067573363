.resultWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  padding-top: 3rem;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.image {
  width: 50%;
}
.subTitle {
  margin-bottom: 1rem;
  text-align: center;
}
.story {
  height: 25vh;
  overflow: scroll;
  padding: 1rem;
  width: 70%;
  margin-bottom: 1rem;
}

@media (max-width: 414px) {
  .resultWrapper {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .resultWrapper {
    width: 100%;
  }
}

.story {
  font-size: 0.8rem;
}
