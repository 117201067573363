@import url(https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: "Noto Serif", serif;
  height: 100%;
  color: #484848;
}

body a {
  text-decoration: none;
  color: #484848;
}

.Header_header__3mObx {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d8e2dc;
  color: white;
  padding: 0 2rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.Header_logo__2ins2 {
  font-family: "Satisfy", cursive;
  font-size: 1.7rem;
}

.Header_signupLogin__jGIOS {
  display: flex;
}

.Header_signup__8rT-6 {
  margin-right: 0.5rem;
}

.Header_login__zfh4r {
  margin-left: 0.5rem;
}

@media (max-width: 414px) {
  .Header_logo__2ins2 {
    font-size: 1.5rem;
  }
  .Header_signup__8rT-6 {
    margin-right: 0;
  }

  .Header_login__zfh4r {
    margin-left: 0;
  }
}

@media (max-width: 375px) {
  .Header_logo__2ins2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1rem;
  }
  .Header_header__3mObx {
    padding: 0 1rem;
  }
  button span {
    font-size: 0.8rem;
  }
}

@media (max-width: 320px) {
  button span {
    font-size: 0.7rem;
  }
  .Header_logo__2ins2 {
    font-size: 1.1rem;
  }
  h3 {
    font-size: 0.8rem;
  }
}

.Modals_cardWrapper__3t4Sj {
  display: flex;
  justify-content: center;
}

.Modals_form__2sdFe {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Modals_SignupContent__1B9Ob {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.Modals_button__2dYp5 {
  position: absolute;
  bottom: 3rem;
}

.Modals_input__e_YvL {
  margin: 0.5rem;
}

.Modals_modalBg__hoIdv {
  background-color: white;
  width: 35%;
  max-height: 70%;
  height: 70%;
  margin: 10% auto;
  border-radius: 5px;
  position: relative;
}

.Modals_forgotPw__37i6D {
  margin-top: 1rem;
}

.Modals_errorMsg__3knwL {
  border: 1px solid orangered;
  background-color: #fcb1a6;
  color: red;
  border-radius: 5px;
  padding: 0.3rem;
  margin: 0.5rem;
}

/* mobile version */

@media (max-width: 1024px) and (max-height: 1366px) {
  .Modals_modalBg__hoIdv {
    height: 40%;
    width: 50%;
  }
}

@media (max-width: 1024px) and (max-height: 789px) {
  .Modals_modalBg__hoIdv {
    height: 70%;
    width: 50%;
  }
}

@media (max-width: 786px) and (max-height: 1024px) {
  .Modals_modalBg__hoIdv {
    height: 55%;
    width: 65%;
  }
  .Modals_errorMsg__3knwL {
    font-size: 0.8rem;
  }
}

@media (max-width: 786px) and (max-height: 789px) {
  .Modals_modalBg__hoIdv {
    height: 70%;
    width: 65%;
  }
  .Modals_errorMsg__3knwL {
    font-size: 0.8rem;
  }
}

@media (max-width: 540px) {
  .Modals_modalBg__hoIdv {
    height: 75%;
  }
}

@media (max-width: 414px) {
  .Modals_modalBg__hoIdv {
    height: 73%;
    width: 80%;
  }
}

@media (max-width: 375px) {
  .Modals_modalBg__hoIdv {
    height: 90%;
  }
  .Modals_SignupContent__1B9Ob {
    padding: 1rem;
  }
}

@media (max-width: 320px) {
  .Modals_button__2dYp5 {
    bottom: 1rem;
  }
  .Modals_modalBg__hoIdv {
    width: 85%;
  }
}

.Home_homeWrapper__2pZRD {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5rem;
}

.Home_imageWrapper__3sXzH {
  display: flex;
  justify-content: center;
}

.Home_image__2ALS6 {
  width: 70%;
}

.Home_button__k4kBI {
  margin-top: 3rem;
}

.Home_buttonDisabled__2kbHE button {
  margin-top: 3rem;
  color: #ced4da;
  border: 1px solid #ced4da;
  cursor: not-allowed;
}

.Home_buttonDisabled__2kbHE:hover {
  background-color: white;
}

.Home_tooltip__3YoCd {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.Home_tooltip__3YoCd .Home_tooltiptext__dGt4- {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.Home_tooltip__3YoCd .Home_tooltiptext__dGt4-::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.Home_tooltip__3YoCd:hover .Home_tooltiptext__dGt4- {
  visibility: visible;
  opacity: 1;
}

/* mobile version */
@media (max-width: 414px) {
  .Home_homeWrapper__2pZRD {
    margin: 2rem 0.5rem;
  }
}

@media (max-width: 320px) {
  .Home_tooltip__3YoCd .Home_tooltiptext__dGt4- {
    padding: 5px;
    left: 40%;
  }
}

.Question_cardWrapper__ozWT- {
  display: flex;
  justify-content: center;
  margin: 5rem;
}

.Question_card__1pavM {
  width: 60%;
  min-height: 25rem;
  padding: 2rem;
}

.Question_cardContent__39ByJ {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Question_cardContent__39ByJ p {
  margin: 2rem;
}

.Question_title__1uxSy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.Question_qButton__3rnFo {
  margin: 1rem;
}

.Question_chocobo__c-g-1 {
  width: 20%;
  position: absolute;
  bottom: 2rem;
  right: 1rem;
}

.Question_moogle__21B1L {
  width: 20%;
  position: absolute;
  bottom: 2rem;
  left: 1rem;
}

@media (max-width: 1024px) {
  .Question_chocobo__c-g-1 {
    top: 28rem;
  }

  .Question_moogle__21B1L {
    top: 28rem;
  }
}

@media (max-width: 540px) {
  .Question_chocobo__c-g-1 {
    width: 30%;
  }

  .Question_moogle__21B1L {
    width: 30%;
  }
  .Question_cardContent__39ByJ p {
    margin: 2rem 1rem;
  }
}

@media (max-width: 414px) {
  .Question_chocobo__c-g-1 {
    top: 22rem;
  }
  .Question_moogle__21B1L {
    top: 22rem;
  }
  .Question_cardContent__39ByJ p {
    margin: 2rem 0rem;
  }
  .Question_cardWrapper__ozWT- {
    margin: 2rem;
  }
  .Question_card__1pavM {
    width: 100%;
    padding: 1rem;
    min-height: 23rem;
  }
}

@media (max-width: 320px) {
  .Question_chocobo__c-g-1 {
    top: 19rem;
    right: 0;
  }
  .Question_moogle__21B1L {
    top: 19rem;
    left: 0;
  }
  .Question_cardContent__39ByJ p {
    margin: 1rem 0rem;
    font-size: 0.9rem;
  }
  .Question_card__1pavM {
    min-height: 15rem;
  }
  .Question_title__1uxSy {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
}

.Result_resultWrapper__1-deU {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  padding-top: 3rem;
}

.Result_imageWrapper__3i_JI {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.Result_image__bczrK {
  width: 50%;
}
.Result_subTitle__mB6xu {
  margin-bottom: 1rem;
  text-align: center;
}
.Result_story__3pn9v {
  height: 25vh;
  overflow: scroll;
  padding: 1rem;
  width: 70%;
  margin-bottom: 1rem;
}

@media (max-width: 414px) {
  .Result_resultWrapper__1-deU {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .Result_resultWrapper__1-deU {
    width: 100%;
  }
}

.Result_story__3pn9v {
  font-size: 0.8rem;
}

.Footer_copyRight__1grjn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 1rem 1rem 3rem 1rem;
  text-align: center;
}

@media (max-width: 414px) {
  .Footer_copyRight__1grjn {
    font-size: 0.7rem;
  }
}

@media (max-width: 375px) {
  .Footer_copyRight__1grjn {
    font-size: 0.5rem;
  }
}

