.cardWrapper {
  display: flex;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SignupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.button {
  position: absolute;
  bottom: 3rem;
}

.input {
  margin: 0.5rem;
}

.modalBg {
  background-color: white;
  width: 35%;
  max-height: 70%;
  height: 70%;
  margin: 10% auto;
  border-radius: 5px;
  position: relative;
}

.forgotPw {
  margin-top: 1rem;
}

.errorMsg {
  border: 1px solid orangered;
  background-color: #fcb1a6;
  color: red;
  border-radius: 5px;
  padding: 0.3rem;
  margin: 0.5rem;
}

/* mobile version */

@media (max-width: 1024px) and (max-height: 1366px) {
  .modalBg {
    height: 40%;
    width: 50%;
  }
}

@media (max-width: 1024px) and (max-height: 789px) {
  .modalBg {
    height: 70%;
    width: 50%;
  }
}

@media (max-width: 786px) and (max-height: 1024px) {
  .modalBg {
    height: 55%;
    width: 65%;
  }
  .errorMsg {
    font-size: 0.8rem;
  }
}

@media (max-width: 786px) and (max-height: 789px) {
  .modalBg {
    height: 70%;
    width: 65%;
  }
  .errorMsg {
    font-size: 0.8rem;
  }
}

@media (max-width: 540px) {
  .modalBg {
    height: 75%;
  }
}

@media (max-width: 414px) {
  .modalBg {
    height: 73%;
    width: 80%;
  }
}

@media (max-width: 375px) {
  .modalBg {
    height: 90%;
  }
  .SignupContent {
    padding: 1rem;
  }
}

@media (max-width: 320px) {
  .button {
    bottom: 1rem;
  }
  .modalBg {
    width: 85%;
  }
}
