.homeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5rem;
}

.imageWrapper {
  display: flex;
  justify-content: center;
}

.image {
  width: 70%;
}

.button {
  margin-top: 3rem;
}

.buttonDisabled button {
  margin-top: 3rem;
  color: #ced4da;
  border: 1px solid #ced4da;
  cursor: not-allowed;
}

.buttonDisabled:hover {
  background-color: white;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* mobile version */
@media (max-width: 414px) {
  .homeWrapper {
    margin: 2rem 0.5rem;
  }
}

@media (max-width: 320px) {
  .tooltip .tooltiptext {
    padding: 5px;
    left: 40%;
  }
}
